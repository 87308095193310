import React from "react";
import Input, { NumberInput } from "./Input";
import TextArea from "./TextArea";
import SelectIp from "./SelectIp";
import DateIp, {
  YearPicker,
  TimePicker,
  DateRange,
  TimeRange,
  DateTimeIp,
} from "./DateIp";
import CheckBox from "./CheckBox";
import RadioGroup, { CheckGroup } from "./RadioGroup";
import FileInput from "./FileInput";
import formTypes from "./formTypes";
import NormalSelect from "./NormalSelect";
import EmailTagIp from "./ReactTagIp";
import HtmlEditor from "./Editor";

const SelectField = (props) => {
  const Comp = selectField(props.type);

  return Comp ? <Comp {...props} /> : null;
};

const selectField = (type = formTypes.text) => {
  switch (type) {
    case formTypes.text:
    case formTypes.email:
    case formTypes.search:
    case formTypes.url:
    case formTypes.password:
      return Input;
    case formTypes.tel:
    case formTypes.number:
      return NumberInput;
    case formTypes.textarea:
      return TextArea;
    case formTypes.checkbox:
      return CheckBox;
    case formTypes.radiogroup:
      return RadioGroup;
    case formTypes.checkgroup:
      return CheckGroup;
    case formTypes.select:
      return SelectIp;
    case formTypes.date:
      return DateIp;
    case formTypes.normalSelect:
      return NormalSelect;
    case formTypes.datetime:
      return DateTimeIp;
    case formTypes.daterange:
      return DateRange;
    case formTypes.year:
      return YearPicker;
    case formTypes.time:
      return TimePicker;
    case formTypes.timerange:
      return TimeRange;
    case formTypes.file:
      return FileInput;
    case formTypes.emailTag:
      return EmailTagIp;
    case formTypes.htmlEditor:
      return HtmlEditor;
    default:
      return () => null;
  }
};

export default SelectField;
