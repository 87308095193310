import React, { lazy } from "react";
import AuthRoute from "../../components/auth/AuthRoute";
import BrouchureLinks from "../../components/layout/BrouchureLinks";
import RoleRoutes from "../RoleRoutes";

export const pathName = "/sales-manager";

export const paths = {
    addEnquiry: "/add-enquiry",
    avail: "/availability-and-cost-sheet",
    leads: "/leads",
    reports: "/reports",
    targets: "/targets",
    aprt: "/apartment/:apartmentId",
    bookings: "/bookings",
    costSheet: "/cost-sheet/:apartmentId",
    search:"/search",
};

export const landing = `${pathName}${paths.leads}`;

export const pages = {
    landing,
    addEnquiry: `${pathName}${paths.addEnquiry}`,
    avail: `${pathName}${paths.avail}`,
    reports: `${pathName}${paths.reports}`,
    targets: `${pathName}${paths.targets}`,
    bookings: `${pathName}${paths.bookings}`,
    costSheet: `${pathName}${paths.costSheet}`,
};

export const menu = [
    {
        title: "Leads",
        to: landing,
    },
    {
        title: "Add New Lead",
        to: pages.addEnquiry,
    },
    {
        title: "Availability and Cost Sheet",
        to: pages.avail,
    },
    {
        title: "Reports",
        to: pages.reports,
    },
    {
        title: "Targets",
        to: pages.targets,
    },
    {
        title: "Booking Status",
        to: pages.bookings,
    },
];

export const routes = [
    {
        path: paths.addEnquiry,
        component: lazy(() => import("./AddLeads")),
    },
    {
        path: paths.leads,
        component: lazy(() => import("./Leads")),
        exact: true,
    },
    {
        path: paths.reports,
        component: lazy(() => import("./Reports")),
        exact: true,
    },
    {
        path: `${paths.leads}/edit/:leadId`,
        component: lazy(() => import("./EditLeads")),
    },
    {
        path: `${paths.leads}/view/:leadId`,
        component: lazy(() => import("../tele-caller/LeadDetails")),
    },
    {
        path: `${paths.leads}/convert/:leadId`,
        component: lazy(() => import("./Convert")),
    },
    {
        path: paths.avail,
        component: lazy(() => import("./Availability")),
        exact: true,
    },
    {
        path: `${paths.avail}/apartment/:apartmentId`,
        component: lazy(() => import("./Apartment")),
    },
    {
        path: `${paths.avail}/tower-wise-availability`,
        component: lazy(() => import("./TowerAvailability")),
    },
    {
        path: `${paths.avail}/project-cost-sheet`,
        component: lazy(() => import("./ProjectCostSheet")),
    },
    {
        path: `${paths.avail}/tower-reports`,
        component: lazy(() => import("./TowerReport")),
    },
    {
        path: paths.costSheet,
        component: lazy(() => import("./CostSheet")),
    },
    {
        path: paths.targets,
        component: lazy(() => import("../tele-caller/Target")),
    },
    {
        path: paths.bookings,
        component: lazy(() => import("./Bookings")),
        exact: true,
    },
    {
        path: `${paths.bookings}/booking-details/:bookingId`,
        component: lazy(() => import("./BookingDetails")),
    },
    {
        path: `${paths.search}/view/:leadId`,
        component: lazy(() => import("../tele-caller/LeadDetails")),
    },
    {
        path: paths.search,
        component: lazy(() => import("../OverallSearchResult")),
    },
];

export const allowRoles = [3];

export default (
    <AuthRoute path={pathName}>
        <RoleRoutes
            title="Sales Manager"
            routes={routes}
            allowRoles={allowRoles}
            menu={menu}
            landing={landing}
            headerRight={<BrouchureLinks />}
        />
    </AuthRoute>
);
