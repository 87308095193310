import React, { lazy } from "react";
import AuthRoute from "../../components/auth/AuthRoute";
import RoleRoutes from "../RoleRoutes";
import BrouchureLinks from "../../components/layout/BrouchureLinks";

export const pathName = "/sales-general-manager";

export const paths = {
    dashboard: "/dashboard",
    target: "/target",
    booking: "/booking-approval",
    team: "/team-details",
    avail: "/availability-and-cost-sheet",
    leadApproval: "/lead-approval",
    reports: "/reports",
    lead_reports: "/lead-reports",
    search:"/search",
};

export const landing = `${pathName}${paths.dashboard}`;

export const pages = {
    landing,
    target: `${pathName}${paths.target}`,
    booking: `${pathName}${paths.booking}`,
    team: `${pathName}${paths.team}`,
    avail: `${pathName}${paths.avail}`,
    leadApproval: `${pathName}${paths.leadApproval}`,
    reports: `${pathName}${paths.reports}`,
    lead_reports: `${pathName}${paths.lead_reports}`,
};

export const menu = [
    {
        title: "Dashboard",
        to: landing,
    },
    {
        title: "Target",
        to: pages.target,
    },
    {
        title: "Availability and Cost Sheet",
        to: pages.avail,
    },
    {
        title: "Booking Approval",
        to: pages.booking,
    },
    {
        title: "Sales Team",
        to: pages.team,
    },
    {
        title: "Dead Lead Approval",
        to: pages.leadApproval,
    },
    {
        title: "Reports",
        to: pages.reports,
    },
    {
        title: "New Reports",
        to: pages.lead_reports,
    },
];

export const routes = [
    {
        path: paths.dashboard,
        component: lazy(() => import("./Dashboard")),
    },
    {
        path: paths.target,
        component: lazy(() => import("./Target")),
    },
    {
        path: paths.avail,
        component: lazy(() => import("../sales-manager/Availability")),
        exact: true,
    },
    {
        path: `${paths.avail}/apartment/:apartmentId`,
        component: lazy(() => import("../sales-manager/Apartment")),
    },
    {
        path: `${paths.avail}/tower-wise-availability`,
        component: lazy(() => import("../sales-manager/TowerAvailability")),
    },
    {
        path: `${paths.avail}/project-cost-sheet`,
        component: lazy(() => import("../sales-manager/ProjectCostSheet")),
    },
    {
        path: `${paths.avail}/tower-reports`,
        component: lazy(() => import("../sales-manager/TowerReport")),
    },
    {
        path: paths.booking,
        component: lazy(() => import("./Booking")),
        exact: true,
    },
    {
        path: paths.team,
        component: lazy(() => import("./SalesTeam")),
        exact: true,
    },
    {
        path: `${paths.team}/view/:leadId`,
        component: lazy(() => import("../tele-caller/LeadDetails")),
    },
    {
        path: `${paths.team}/convert/:leadId`,
        component: lazy(() => import("../sales-manager/Convert")),
    },
    {
        path: `${paths.booking}/booking-details/:bookingId`,
        component: lazy(() => import("../sales-manager/BookingDetails")),
    },
    {
        path: paths.leadApproval,
        component: lazy(() => import("./LeadApproval")),
        exact: true,
    },
    {
        path: `${paths.leadApproval}/view/:leadId`,
        component: lazy(() => import("../tele-caller/LeadDetails")),
    },
    {
        path: paths.reports,
        component: lazy(() => import("./Reports")),
        exact: true,
    },
    {
        path: paths.lead_reports,
        component: lazy(() => import("./LeadReports")),
        exact: true,
    },
    {
        path: `${paths.search}/view/:leadId`,
        component: lazy(() => import("../tele-caller/LeadDetails")),
    },
    {
        path: paths.search,
        component: lazy(() => import("../OverallSearchResult")),
    },
];

export const allowRoles = [7];

export default (
    <AuthRoute path={pathName}>
        <RoleRoutes
            title="Sales General Manager"
            routes={routes}
            menu={menu}
            landing={landing}
            allowRoles={allowRoles}
            headerRight={<BrouchureLinks />}
        />
    </AuthRoute>
);
