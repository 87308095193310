const urls = {
  baseURL: process.env.REACT_APP_BASE_URL,
  login: "auth/login/",
  resendOtp: "auth/resend-otp/",
  logout: "auth/logout/",
  changePwd: "auth/change-password/",
  search: "leads/search/overall/",

  // tele caller
  leads: "leads/",
  leadDetail: (id) => `leads/${id}/`,
  addFollowUp: (id) => `leads/${id}/add_followup/`,
  resendEnquiry: (id) => `leads/${id}/resend_enquiry/`,
  leadHistory: (id) => `leads/${id}/view_history/`,
  getSalesTeam: (id) => `leads/${id}/get_sales_team/`,
  salesTeamReport: "leads/sales_team_report/",
  checkEnquiry: "leads/check_enquiry/",

  // portal leads
  portalLeads: "leads/portals/",
  portalLeadsDetail: (id) => `leads/portals/${id}/`,
  assignPortalLeads: "leads/portals/assign_leads/",

  // Super Receptionist
  superReceptionist: "leads/super-receptionist/",
  portalReceptionist: (id) => `leads/super-receptionist/${id}/`,
  assignReceptionistLeads: "leads/super-receptionist/assign_leads/",

  // marketing manager
  addTarget: "teams/target/",
  emplList: "teams/target/list_employee/",
  targetList: "teams/target/",
  campaignBudget: "master-values/campaign-budget/",
  customerFeedback: "leads/tele-caller/feedback/",
  //add master

  //sales
  crmSales: "crms/",
};

const teleLeads = "leads/tele-caller/";

export const teleCallerUrls = {
  teamList: teleLeads + "my_teams_list/",
  enquiryTabs: teleLeads + "enquiry_tab/",
  reportTabs: teleLeads + "report_tab/",
  report: teleLeads + "report/",
  targets: teleLeads + "my_target/",
  addSalesManager: (id) => `${teleLeads}${id}/add_sales_manager/`,
  editSalesManager: (id) => `${teleLeads}${id}/edit_sales_manager/`,
  removeSalesManager: (id) => `${teleLeads}${id}/remove_sales_manager/`,
  tabs: "users/tele-caller/lead_tab/",
  lead_list: "users/tele-caller/lead_list/",
  approveMul: "users/tele-caller/all_approved_deny/",
  salesSVDReport: "users/tele-caller/sales_svd_report/",
  teamTargetReport: "users/tele-caller/team_target_report/",
  feedback: teleLeads + "feedback/",
};

const masterValues = "master-values/";

export const masterUrls = {
  master: masterValues,
  activeMaster: masterValues + "is_active/",
  addProject: "projects/project/",
  addLocation: masterValues + "location/",
  addSource: masterValues + "sources/",
  addCampaign: masterValues + "campaign/",
  addPrimarySource: masterValues + "primary-source/",
  campaignBudget: masterValues + "campaign-budget/",
};

export const marketingManagerUrls = {
  dashboard: "users/marketing-manager/dashboard/",
  reports: "users/marketing-manager/",
  reportTabs: "users/marketing-manager/report_tab/",
};

const salesUrl = "leads/sales-manager/";
const projects = "projects/";

export const salesManagerUrls = {
  leads: salesUrl,
  tabs: salesUrl + "lead_tab/",
  towers: projects,
  projects: `${projects}project/layout_apartment/`,
  blockApartment: `${projects}project/blocking_apartment/`,
  unBlockApartment: (id) => `${projects}project/${id}/unblocking_apartment/`,
  aprtDetail: (id) => `${projects}cost-sheet/apartment/${id}/`,
  towerReport: `${projects}project/tower_report/`,
  masterReport: (id) =>
    `${projects}project/project_master_availability/?project=${id}`,
  booking: "bookings/",
  bookingTabs: "bookings/booking_count/",
  costSheet: `projects/cost-sheet/apartment/pdf/`,
  reports: `${salesUrl}report/`,
};

const gmUrl = "users/sales-general-manager/";

export const salesGmUrls = {
  dashboard: gmUrl + "dashboard/",
  salesTeamLead: gmUrl + "sales_team_leads/",
  lead: gmUrl + "lead/",
  tabs: gmUrl + "lead_tab/",
  approveLead: (id) => `${gmUrl}${id}/approved_deny/`,
  approveBooking: `bookings/all_approved_deny/`,
  approveMul: "users/sales-general-manager/all_approved_deny/",
  reports: "users/sales-general-manager/reports/",
  reportsPagination: "users/sales-general-manager/reports_pagination/",
  lead_reports: "users/sales-general-manager/lead_reports/",
  sendMail: "auth/send-report-email/",
};

export const salesCOUrls = {
  leads: "users/sales-coordinator/list_leads/",
  leadTab: "users/sales-coordinator/list_lead_count/",
  conversion: "users/sales-coordinator/booking_leads/",
  conversionTab: "users/sales-coordinator/booking_lead_count/",
  allocateCab: (id) => `users/sales-coordinator/${id}/allocate_cab/`,
  reports: "users/sales-coordinator/reports/",
};

export const frontOfficeExecutiveUrls = {
  report_tab: "users/front-office/report_tab/",
  report: "users/front-office/",
};

export const crmExecutiveUrls = {
  leads: "/crms",
  cancelled: "/crms/?is_canceled=1",
  crmCustomers: "crms/customers/",
  activityStatus: "crms/booking-call-activities/",
  email: "emails/",
  projects: "crms/projects/",
  paymentNotes: "payment-notes/",
  creditNotes: "credit-notes/",
  debitNotes: "payment-notes/debit-notes/",
  advanceCreditNotes: (booking_id) =>
    `payment-notes/credit-notes/advance_notes/?booking=${booking_id}`,
  receipts: "receipts/",
  advanceReceipts: "receipts/advance_receipts/",
  advanceAmountAdjustment: (receipt_id, booking_id) =>
    `${crmExecutiveUrls.receipts}${receipt_id}/advance_amount_adjustment/?booking=${booking_id}`,
  getCreditNote: (notes_id, booking_id) =>
    `${crmExecutiveUrls.paymentNotes}credit-notes/${notes_id}/?booking=${booking_id}`,
  advanceCreditNoteAdjustment: (notes_id, booking_id) =>
    `${crmExecutiveUrls.paymentNotes}credit-notes/${notes_id}/advance_amount_adjustment/?booking=${booking_id}`,
  bankReconciliation: "receipts/bank-reconciliation",
  entryForBankReconciliation: (receipt_id) =>
    `receipts/bank-reconciliation/${receipt_id}/update_reconciliation/`,
  getBookingAmountAdjustment: (bookingId) =>
    `receipts/${bookingId}/get_booking_amount_adjustment/`,
};

const documentsUrl = "documents/";
export const documentsUrls = {
  tower_availability: documentsUrl + "tower/availability/",
  statement_of_customer: (id) => documentsUrl + `statement-of-customer/${id}/`,
};

export default urls;
