import axios from "axios";
import { deleteLocalUser } from "../utils/localstorage";
import urls from "./urls";

const { CancelToken, create, isCancel } = axios;

const clientApi = create({
    baseURL: urls.baseURL,
});

clientApi.interceptors.response.use(undefined, (error) => {
    if (error && error.response && error.response.status === 401) {
        delete clientApi.defaults.headers.common["Authorization"];
        deleteLocalUser();
        window.location.replace("/logout");
    }
    return Promise.reject(error);
});

export { CancelToken, isCancel };

export default clientApi;
