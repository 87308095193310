import React from "react";
import ModalOverlay from "./ModalOverlay";
import ModalContent from "./ModalContent";
import Portal from "../common/Portal";

const Modal = ({
  id = "portal-modal",
  isOpen = false,
  children,
  close,
  contentStyle = {},
  overlayStyle = {},
  overlayClass = "modal-wrapper",
  contentClass = "modal-container",
}) => {
  if (isOpen) {
    return (
      <Portal id={id}>
        <ModalOverlay onClick={close} className={overlayClass} style={overlayStyle}>
          <ModalContent className={contentClass} style={contentStyle}>
            {children}
          </ModalContent>
        </ModalOverlay>
      </Portal>
    );
  }
  return null;
};

export default Modal;
