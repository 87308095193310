import React from "react";
import { Helmet } from "react-helmet";
import useUser from "../../hooks/user/useUser";
import Header from "./Header";
import Menu from "./Menu";

const Layout = ({ title = "Telecaller", children, menu, headerRight }) => {
  const { token } = useUser()[0];

  return (
    <>
      <Helmet titleTemplate="%s | BSS">
        <title>{title}</title>
      </Helmet>
      <div className="sticky-header">
        <Header headerRight={headerRight} />
        {token && <Menu menu={menu} />}
      </div>
      {children}
    </>
  );
};

export default Layout;
