import React, {
    createContext,
    useCallback,
    useContext,
    useEffect,
} from "react";
import { masterUrls } from "../../apiService/urls";
import useFetchData from "../../hooks/http/useFetchData";
import useUser from "../../hooks/user/useUser";

const MasterContext = createContext([false, {}, false]);
export const useMasterValues = () => {
    return useContext(MasterContext).value;
};

export const useMasterActions = () => {
    return useContext(MasterContext).actions;
};

const MasterData = ({ children }) => {
    const { token, role } = useUser()[0];
    const userRole = role?.id ?? "";
    const [state, { setUrl, reload }] = useFetchData();

    useEffect(() => {
        if (token) {
            setUrl(
                `${masterUrls.activeMaster}?date=${new Date().toISOString()}`
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [token, userRole]);

    const reloadMaster = useCallback(() => {
        reload(masterUrls.activeMaster);
    }, [reload]);

    const data = state?.data ?? {};

    return (
        <MasterContext.Provider
            value={{
                value: [state.fetching, data, state.error],
                actions: { reload: reloadMaster },
            }}
        >
            {children}
        </MasterContext.Provider>
    );
};

export default MasterData;
