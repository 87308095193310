import React, { useEffect } from "react";
import Loader from "../form/Loader";
import urls from "../../apiService/urls";
import useSubmit from "../../hooks/http/useSubmit";
import { useHistory } from "react-router-dom";
import useUser from "../../hooks/user/useUser";

const Logout = () => {
    let history = useHistory();
    const [state, { clear }] = useUser();

    const handleLogout = () => {
        clear();
        history.replace("/");
    };

    const [fetching, submit] = useSubmit({
        success: handleLogout,
        error: handleLogout,
        hideToast: true,
    });


    useEffect(() => {
        if (state.token) {
            submit({
                url: urls.logout,
                method: "POST",
            });
        } else {
            handleLogout();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="abs-full d-flex justify-content-center align-items-center">
            {fetching && <Loader size={30} />}
        </div>
    );
};

export default Logout;
