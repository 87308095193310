import React, { lazy } from "react";
import AuthRoute from "../../components/auth/AuthRoute";
import BrouchureLinks from "../../components/layout/BrouchureLinks";
import RoleRoutes from "../RoleRoutes";

export const pathName = "/sales-manager-team-lead";

export const paths = {
  addEnquiry: "/add-enquiry",
  avail: "/availability-and-cost-sheet",
  leads: "/leads",
  targets: "/targets",
  aprt: "/apartment/:apartmentId",
  bookings: "/bookings",
  team: "/team-details",
  approvals: "approvals",
  search: "/search",
};

export const landing = `${pathName}${paths.leads}`;

export const pages = {
  landing,
  addEnquiry: `${pathName}${paths.addEnquiry}`,
  avail: `${pathName}${paths.avail}`,
  targets: `${pathName}${paths.targets}`,
  bookings: `${pathName}${paths.bookings}`,
  apartment: (id) => `${pathName}${paths.aprt}`.replace(":apartmentId", id),
  team: `${pathName}${paths.team}`,
  approvals: `${pathName}${paths.approvals}`,
};

export const menu = [
  {
    title: "Leads",
    to: landing,
  },
  {
    title: "New Lead",
    to: pages.addEnquiry,
  },
  {
    title: "Availability and Cost Sheet",
    to: pages.avail,
  },
  {
    title: "Targets",
    to: pages.targets,
  },
  {
    title: "Booking Status",
    to: pages.bookings,
  },
  {
    title: "Team Details",
    to: pages.team,
  },
  {
    title: "Approvals",
    to: pages.approvals,
  },
];

export const routes = [
  {
    path: paths.addEnquiry,
    component: lazy(() => import("../sales-manager/AddLeads")),
  },
  {
    path: paths.leads,
    component: lazy(() => import("../sales-manager/Leads")),
    exact: true,
  },
  {
    path: `${paths.leads}/edit/:leadId`,
    component: lazy(() => import("../sales-manager/EditLeads")),
  },
  {
    path: `${paths.leads}/view/:leadId`,
    component: lazy(() => import("../tele-caller/LeadDetails")),
  },
  {
    path: `${paths.leads}/convert/:leadId`,
    component: lazy(() => import("../sales-manager/Convert")),
  },
  {
    path: paths.avail,
    component: lazy(() => import("../sales-manager/Availability")),
    exact: true,
  },
  {
    path: `${paths.avail}/apartment/:apartmentId`,
    component: lazy(() => import("../sales-manager/Apartment")),
  },
  {
    path: `${paths.avail}/tower-wise-availability`,
    component: lazy(() => import("../sales-manager/TowerAvailability")),
  },
  {
    path: paths.targets,
    component: lazy(() => import("./Targets")),
  },
  {
    path: paths.bookings,
    component: lazy(() => import("./Bookings")),
    exact: true,
  },
  {
    path: `${paths.bookings}/booking-details/:bookingId`,
    component: lazy(() => import("../sales-manager/BookingDetails")),
  },
  {
    path: paths.team,
    component: lazy(() => import("./TeamDetails")),
    exact: true,
  },
  {
    path: `${paths.team}/view/:leadId`,
    component: lazy(() => import("../tele-caller/LeadDetails")),
  },
  {
    path: `${paths.search}/view/:leadId`,
    component: lazy(() => import("../tele-caller/LeadDetails")),
  },
  {
    path: paths.search,
    component: lazy(() => import("../OverallSearchResult")),
  },
  {
    path: paths.approvals,
    component: lazy(() => import("../OverallSearchResult")),
  },
];

export const allowRoles = [4];

export default (
  <AuthRoute path={pathName}>
    <RoleRoutes
      title="Sales Manager Team Lead"
      routes={routes}
      allowRoles={allowRoles}
      menu={menu}
      landing={landing}
      headerRight={<BrouchureLinks />}
    />
  </AuthRoute>
);
