import React from "react";
import { UserContext, useUserReducer } from "../../hooks/user/useUser";

const UserProvider = ({ children }) => {
    const value = useUserReducer();

    return (
        <UserContext.Provider value={value}>{children}</UserContext.Provider>
    );
};

export default UserProvider;
