import React, { lazy } from "react";
import AuthRoute from "../../components/auth/AuthRoute";
import ProjectsDropDown from "../../components/layout/CrmProject";
import RoleRoutes from "../RoleRoutes";

export const pathName = "/crm-executives";

export const paths = {
  crm: "/crm",
  sales: "/sales",
  Apartment: "/src/components/sales-manager/availability/ApartmentModal.js",
  booking: "../sales-manager/BookingDetails.js",
  costSheet: "../sales-manager/CostSheet.js",
};

export const landing = `${pathName}${paths.crm}`;
export const sales = `${pathName}${paths.sales}`;

// export const pages = {
//     crm_listing: `${pathName}${paths.crm}`,
//     crm_details: `${pathName}${paths.crm}/view/:customerId`,

//     sales: `${pathName}${paths.sales}`,

//     avail: `${pathName}${paths.avail}`,
//     TowerReport: `${pathName}${paths.avail}`,
//     Apartment: `${pathName}${paths.Apartment}`,
//     booking: `${pathName}${paths.booking}`,
//     costSheet: `${pathName}${paths.costSheet}`,
// };

export const menu = [
  {
    title: "CRM",
    to: landing,
  },
  {
    title: "Sales",
    to: sales,
  },
];

export const routes = [
  {
    path: paths.crm,
    exact: true,
    component: lazy(() => import("./crm")),
  },
  {
    path: `${paths.crm}/view/:customerId`,
    exact: true,
    component: lazy(() => import("./CRM/CustomerMaster/CustomerDetail")),
  },
  {
    path: `${paths.crm}/view/:customerId/final-notice`,
    exact: true,
    component: lazy(() =>
      import(
        "../../components/crm-executive/crm/PaymentDetailPage/TabSection/FinalRemainderLetter"
      )
    ),
  },
  {
    path: `${paths.crm}/payment/view/:customerId`,
    exact: true,
    component: lazy(() => import("./CRM/CustomerPayments/PaymentDetailPage")),
  },
  {
    path: `${paths.crm}/poa/add/:customerId`,
    exact: true,
    component: lazy(() =>
      import("../../components/crm-executive/crm/Add/PoaInfoAdd")
    ),
  },
  {
    path: `${paths.crm}/poa/:poaId/edit/:customerId`,
    exact: true,
    component: lazy(() =>
      import("../../components/crm-executive/crm/editDetails/PoaDetailsEdit")
    ),
  },
  {
    path: `${paths.crm}/contact-person/add/:customerId`,
    exact: true,
    component: lazy(() =>
      import("../../components/crm-executive/crm/Add/AddContactPerson")
    ),
  },
  {
    path: `${paths.crm}/contact-person/:conId/edit/:customerId`,
    exact: true,
    component: lazy(() =>
      import("../../components/crm-executive/crm/editDetails/ContactPersonEdit")
    ),
  },
  {
    path: `${paths.crm}/view/:customerId/generate-customer-receipt`,
    exact: true,
    component: lazy(() =>
      import(
        "../../components/crm-executive/crm/Receipts/GenerateCustomerReceipt"
      )
    ),
  },
  {
    path: `${paths.crm}/view/:customerId/payment-received-receipt`,
    exact: true,
    component: lazy(() =>
      import(
        "../../components/crm-executive/crm/Receipts/PaymentReceivedReceipt"
      )
    ),
  },
  {
    path: `${paths.crm}/payment/view/:customerId/payment-received-from-customer`,
    exact: true,
    component: lazy(() =>
      import(
        "../../components/crm-executive/crm/Receipts/PaymentReceivedReceipt"
      )
    ),
  },
  {
    path: `${paths.crm}/view/:customerId/payment-received-receipt/:receiptId`,
    exact: true,
    component: lazy(() =>
      import(
        "../../components/crm-executive/crm/Receipts/PaymentReceivedDetail"
      )
    ),
  },
  {
    path: `${paths.crm}/view/:customerId/credit-notes/:noteId`,
    exact: true,
    component: lazy(() =>
      import(
        "../../components/crm-executive/crm/PaymentDetailPage/TabSection/CreditNoteDetails"
      )
    ),
  },
  {
    path: `${paths.crm}/customer/:customerId/outstanding-payment`,
    exact: true,
    component: lazy(() =>
      import(
        "../../components/crm-executive/crm/PaymentDetailPage/TabSection/CustomerOutstandingPayments"
      )
    ),
  },
  {
    path: `${paths.crm}/customer/:customerId/payment-statement`,
    exact: true,
    component: lazy(() =>
      import(
        "../../components/crm-executive/crm/PaymentDetailPage/TabSection/CustomerPaymentStatement"
      )
    ),
  },

  {
    path: paths.sales,
    exact: true,
    component: lazy(() => import("./sales")),
  },
  {
    path: `${paths.sales}/view/:customerId`,
    component: lazy(() => import("./CRM/CustomerMaster/CustomerDetail")),
  },
  {
    path: `${paths.sales}/cancellation/view/:customerId`,
    component: lazy(() => import("./Sales/cancellationDetails")),
  },
  {
    path: `${paths.sales}/apartment/:apartmentId`,
    component: lazy(() => import("../sales-manager/Apartment.js")),
  },
  {
    path: `${paths.sales}/tower-wise-availability`,
    component: lazy(() => import("../sales-manager/TowerAvailability.js")),
  },
  {
    path: `${paths.sales}/tower-reports`,
    component: lazy(() => import("../sales-manager/TowerReport")),
  },
  {
    path: `${paths.sales}/project-cost-sheet`,
    component: lazy(() => import("../sales-manager/ProjectCostSheet")),
  },
  {
    path: `${paths.sales}/booking-details/:bookingId`,
    exact: true,
    component: lazy(() => import("./Sales/BookingDetails")),
  },
  {
    path: `${paths.sales}/add/:bookingId`,
    exact: true,
    component: lazy(() => import("./Sales/AddCancellation")),
  },

  {
    path: `${paths.crm}/bank-details/add/:customerId`,
    exact: true,
    component: lazy(() =>
      import("../../components/crm-executive/crm/Add/AddBankDetail")
    ),
  },
  {
    path: `${paths.crm}/bank-details/edit/:customerId`,
    exact: true,
    component: lazy(() =>
      import("../../components/crm-executive/crm/editDetails/EditBankDetails")
    ),
  },
  {
    path: `${paths.crm}/co-applicant/add/:customerId`,
    component: lazy(() =>
      import("../../components/crm-executive/crm/Add/AddCoApplicant")
    ),
  },
];

export const allowRoles = [11];

export default (
  <AuthRoute path={pathName}>
    <RoleRoutes
      title="CRM - Executives"
      routes={routes}
      menu={menu}
      landing={landing}
      allowRoles={allowRoles}
      headerRight={<ProjectsDropDown />}
    />
  </AuthRoute>
);
