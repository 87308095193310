import React from "react";
import { useLocation } from "react-router-dom";
import { useParams } from "react-router-dom";
import urls from "../../apiService/urls";

const PdfViewer = ({ docViewer = false }) => {
  const { docType, customerId, type, path, query } = useParams();

  const src = docViewer
    ? `${urls.baseURL}${docType}/${path}/${query}`
    : `${urls.baseURL}${docType}/${path}/${customerId}/?type=${type}`;

  return (
    <>
      <iframe
        src={src}
        className="modal-wrapper"
        title={path}
        style={{ border: "none", padding: "0px" }}
      />
    </>
  );
};

export default PdfViewer;

export const DocViewer = () => {
  const { docType, path } = useParams();
  const { search } = useLocation();

  const src = `${urls.baseURL}${docType}/${path}/${search}`;

  return (
    <>
      <iframe
        src={src}
        className="modal-wrapper"
        title={path}
        style={{ border: "none", padding: "0px" }}
      />
    </>
  );
};
