import { useMemo, useCallback } from "react";
import useForm from "./useForm";
import { setValue as onChange } from "./formReducer";

const useField = (name = "") => {
    const [state, dispatch] = useForm();
    const { values, errors } = state;

    const value = useMemo(() => {
        return values[name];
    }, [values, name]);

    const err = useMemo(() => {
        return errors[name];
    }, [errors, name]);

    const setValue = useCallback((val) => {
        dispatch(onChange(name, val));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [name]);

    return {
        value,
        err,
        setValue,
    };
};

export default useField;
