import {
    useReducer,
    useContext,
    createContext,
    useCallback,
    useEffect,
} from "react";
import clientApi from "../../apiService/clientApi";
import {
    deleteLocalUser,
    loadLocalState,
    saveLocalState,
} from "../../utils/localstorage";
import userReducer, { clearUser, init, updateUser } from "./userReducer";

const intialValues = loadLocalState() || init;
if (intialValues.token) {
    clientApi.defaults.headers.common.Authorization =
        "Token " + intialValues.token;
}

export const useUserReducer = () => {
    const [state, dispatch] = useReducer(userReducer, intialValues);

    useEffect(() => {
        if (state.token) {
            clientApi.defaults.headers.common.Authorization =
                "Token " + state.token;
        }
        saveLocalState(state);
    }, [state]);

    const update = useCallback((data) => {
        dispatch(updateUser(data));
    }, []);

    const clear = useCallback(() => {
        delete clientApi.defaults.headers.common.Authorization;
        deleteLocalUser();
        dispatch(clearUser());
    }, []);

    return [state, { update, clear }];
};

export const UserContext = createContext();
const useUser = () => useContext(UserContext);

export default useUser;
