import React, { lazy } from "react";
import AuthRoute from "../../components/auth/AuthRoute";
import SalesReport from "../../components/layout/SalesReport";
import RoleRoutes from "../RoleRoutes";

export const pathName = "/tele-caller-team-lead";

export const paths = {
    addEnquiry: "/add-enquiry",
    enquiry: "/enquiry",
    reports: "/reports",
    targets: "/targets",
    teamLeads: "/team-leads",
    teamDetails: "/team-details",
    portalLeads:"/portal-leads",
    superReceptionist:"/super-receptionist",
    search:"/search",
    approvals: "/approvals",
};

export const landing = `${pathName}${paths.addEnquiry}`;


export const menu = [
    {
        title: "Add Enquiry",
        to: landing,
    },
    {
        title: "Enquiry",
        to: `${pathName}${paths.enquiry}`,
    },
    {
        title: "Reports",
        to: `${pathName}${paths.reports}`,
    },
    {
        title: "Targets",
        to: `${pathName}${paths.targets}`,
    },
    {
        title: "Team Details",
        to: `${pathName}${paths.teamLeads}`,
    },
    {
        title: "Sales inprocess / Followup",
        to: `${pathName}${paths.teamDetails}`,
    },
    {
        title: "Portal Leads",
        to: `${pathName}${paths.portalLeads}`,
    },
    {
        title: "Super Receptionist",
        to: `${pathName}${paths.superReceptionist}`,
    },
    {
        title: "Dead Leads Approvals",
        to: `${pathName}${paths.approvals}`,
    },
];

export const routes = [
    {
        path: paths.addEnquiry,
        component: lazy(() => import("../tele-caller/AddLead")),
    },
    {
        path: paths.enquiry,
        exact: true,
        component: lazy(() => import("../tele-caller/Leads")),
    },
    {
        path: `${paths.enquiry}/edit/:leadId`,
        component: lazy(() => import("../tele-caller/EditLead")),
    },
    {
        path: `${paths.enquiry}/view/:leadId`,
        component: lazy(() => import("../tele-caller/LeadDetails")),
    },
    {
        path: `${paths.approvals}/view/:leadId`,
        component: lazy(() => import("../tele-caller/LeadDetails")),
    },
    {
        path: paths.reports,
        component: lazy(() => import("./Reports")),
    },
    {
        path: paths.teamDetails,
        exact: true,
        component: lazy(() => import("./TeamDetails")),
    },
    {
        path: paths.teamLeads,
        exact: true,
        component: lazy(() => import("./TeamLeads")),
    },
    {
        path: `${paths.teamLeads}/edit/:leadId`,
        component: lazy(() => import("../tele-caller/EditLead")),
    },
    {
        path: `${paths.teamLeads}/view/:leadId`,
        component: lazy(() => import("../tele-caller/LeadDetails")),
    },
    {
        path: paths.targets,
        component: lazy(() => import("./Targets")),
    },
    {
        path: paths.portalLeads,
        component: lazy(() => import("./PortalLeads")),
    },
    {
        path: paths.superReceptionist,
        component: lazy(() => import("./SuperReceptionist")),
    },
    {
        path: paths.approvals,
        component: lazy(() => import("./Approvals")),
    },
    {
        path: `${paths.search}/view/:leadId`,
        component: lazy(() => import("../tele-caller/LeadDetails")),
    },
    {
        path: `${paths.search}/take-lead/:leadId`,
        exact:true,
        component: lazy(() => import("../TakeNewLead")),
    },
    {
        path: paths.search,
        component: lazy(() => import("../OverallSearchResult")),
    },
    
];

export const allowRoles = [2];

export default (
    <AuthRoute path={pathName}>
        <RoleRoutes
            title="Telecaller Team Lead"
            routes={routes}
            allowRoles={allowRoles}
            menu={menu}
            landing={landing}
            headerRight={<SalesReport />}
        />
    </AuthRoute>
);
